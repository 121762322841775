<template>
    <div>
        <label>Первое уведомление через</label>
        <div class="input-group">
            <input type="number" min="10" class="form-control" v-model.number="settings.first">
            <div class="input-group-append">
                <span class="input-group-text">мин</span>
            </div>
        </div>

        <label class="mt-1">Последующие через каждые</label>
        <div class="input-group">
            <input type="number" min="10" class="form-control" v-model.number="settings.delay">
            <div class="input-group-append">
                <span class="input-group-text">мин</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TimerLongSettings',
    props: ['settings']
}
</script>