<template>
    <div>
        <h5>Подписаться можно на странице проекта</h5>
        <p class="text-muted" v-if="Object.keys(settings).length === 0">Пока подписок нет</p>
        <router-link :to="{ name: 'ProjectView', params: { id } }" v-else v-for="(i, id) in settings" :key="id">
            {{ id }}
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'TaskList',
    props: ['settings']
}
</script>