<template>
    <div>
        <label>Подписаться можно на странице проекта</label>
        <p class="text-muted" v-if="Object.keys(settings).length === 0">Пока подписок нет</p>
    </div>
</template>

<script>
export default {
    name: 'SubtaskList',
    props: ['settings']
}
</script>