<template>
	<div style="position:relative">
        <div class="overloading" v-if="loading">
            <div class="spinner-grow avatar-lg text-secondary mt-5" role="status"></div>
        </div>

		<table class="table">
            <thead>
                <tr>
                    <th>Тип уведомления</th>
                    <th>Включено</th>
                    <th>Способы</th>
                    <th>Настройки</th>
                </tr>                
            </thead>
            <tr v-for="(options, type) in form" :key="type" :class="{ 'table-success': options.enabled  }">
                <td width="30%">
                    <b>{{ options.name }}</b>
                    <p class="text-muted" v-if="options.description">{{ options.description }}</p>
                </td>
                <td>
                    <input type="checkbox" :id="`${type}_enabled`" data-switch="none" v-model="options.enabled"/>
                    <label :for="`${type}_enabled`" data-on-label="Вкл" data-off-label="Выкл"></label>
                </td>
                <td>
                    <div class="custom-control custom-checkbox" v-for="transport in options.transport" :key="`${type}_tr_${transport.type}`">
                        <input type="checkbox" class="custom-control-input" :id="`${type}_tr_${transport.type}`" v-model="transport.enabled">
                        <label class="custom-control-label" :for="`${type}_tr_${transport.type}`">{{ transport.title }}</label>
                    </div>
                </td>
                <td>
                    <component v-if="options.settings" :is="options.settings.component" :settings="options.settings.settings" />
                </td>
            </tr>
        </table>
	</div>
</template>
<script>
import { mapState } from 'vuex';
import { sleep, mergeDeep } from '@/plugins/utils'

import TimerLongSettings from './notification/TimerLongSettings.vue'
import TaskList from './notification/TaskList.vue'
import SubtaskList from './notification/SubtaskList.vue'

export default {
	name: 'NotificationsProfile',
	data: () => {
		return {
            loading: false,
            watch: false,
			form: {
                'timer:long': {
                    name: 'Долгий таймер',
                    enabled: false,
                    transport: [
                        {type: 'email', title: 'e-mail', enabled: false},
                        {type: 'browser', title: 'браузер', enabled: true},
                    ],
                    settings: {
                        component: 'timer-long-settings',
                        settings: {
                            first: 60,
                            delay: 60
                        }
                    }
                },
                'task:new': {
                    name: 'Новая задача создана',
                    description: 'Уведомление только при создании задачи другим учатсником проекта',
                    enabled: false,
                    transport: [
                        {type: 'email', title: 'e-mail', enabled: false},
                        {type: 'browser', title: 'браузер', enabled: true},
                    ],
                    settings: {
                        component: 'task-list',
                        settings: {}
                    }
                },
                'task:finished': {
                    name: 'Задача выполнена',
                    description: 'Уведомление только при выполнении задачи другим учатсником проекта',
                    enabled: false,
                    transport: [
                        {type: 'email', title: 'e-mail', enabled: false},
                        {type: 'browser', title: 'браузер', enabled: true},
                    ],
                    settings: {
                        component: 'task-list',
                        settings: {}
                    }
                },
                'subtask:new': {
                    name: 'Новая подзадача создана',
                    description: 'Уведомление только при создании подзадачи другим учатсником проекта',
                    enabled: false,
                    transport: [
                        {type: 'email', title: 'e-mail', enabled: false},
                        {type: 'browser', title: 'браузер', enabled: true},
                    ],
                    settings: {
                        component: 'subtask-list',
                        settings: {}
                    }
                },
                'subtask:finished': {
                    name: 'Подзадача выполнена',
                    description: 'Уведомление только при выполнении подзадачи другим учатсником проекта',
                    enabled: false,
                    transport: [
                        {type: 'email', title: 'e-mail', enabled: false},
                        {type: 'browser', title: 'браузер', enabled: true},
                    ],
                    settings: {
                        component: 'subtask-list',
                        settings: {}
                    }
                }
            }
		};
	},
	mounted() {
        if(this.user.notification_settings) {
            Object.keys(this.user.notification_settings).forEach(type => {
                if(!this.form[type]) return;

                let options = this.user.notification_settings[type]
                if(!options) return;

                this.form[type].enabled = options.enabled

                let transports = {}
                options.transport.forEach(transport => {
                    transports[transport] = true                    
                })

                this.form[type].transport.forEach((transport, i) => {
                    if(transports[transport.type] === true) 
                        this.form[type].transport[i].enabled = true             
                })

                if(options.settings) this.form[type].settings.settings = options.settings
            })
        }
        this.watch = true
	},
	computed: {
		...mapState({
			user: state => state.auth.user,
			settings: state => state.settings,
		})
	},
	methods: {
		async saveData() {
			if(this.loading) return false
			this.loading = true
            
            let settings = {}
            Object.keys(this.form).forEach(type => {
                let options = this.form[type]
                settings[type] = {
                    enabled: options.enabled,
                    transport: [],
                    settings: {}
                }

                options.transport.forEach(transport => {
                    if(transport.enabled) settings[type].transport.push( transport.type )
                })
                if(options.settings) settings[type].settings = options.settings.settings
            })

            this.$store.commit('auth/set_user_notification_settings', settings)
            await this.$store.dispatch('auth/update_notification_settings')
            
            await sleep(150)
			this.loading = false
		}
    },
    watch: {
        form: {
            deep: true,
            handler() {
                if(!this.watch) return;
                console.log('form changed')
                this.saveData()
            }
        }
    },
    components: {
        TimerLongSettings,
        TaskList,
        SubtaskList
    }
};
</script>
<style scoped>
input[data-switch] + label {
    width: 66px;
}
input[data-switch]:checked + label:after {
    left: 44px;
}
.overloading {
    transition: 250ms all ease;
    z-index: 2;
    background-color: rgba(128, 128, 128, 0.328);
    position: absolute;
    height: 100%;
    width: 100%;
    text-align: center;
}
</style>